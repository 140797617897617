body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background: linear-gradient(to right, #1e3c72, #2a5298);
  color: #fff;
  overflow-x: hidden;
}

ul {
  list-style-type: none;
  padding: 0;
}

a {
  color: #00bfff;
  text-decoration: none;
}

h1, h2, h3 {
  color: #fff;
  margin-bottom: 10px;
}

.section {
  padding: 20px;
}

.section .content {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

button:hover {
  color: #00bfff;
}

#progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 5px;
  background: linear-gradient(to right, #00f260, #0575e6);
  z-index: 999;
}

@media (max-width: 768px) {
  .section .content {
    padding: 10px;
  }

  button {
    font-size: 14px;
  }
}
