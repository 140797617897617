.languages-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.language-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
}

.language-chart h3 {
  margin-bottom: 10px;
  color: white;
}
