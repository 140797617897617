.timeline {
    position: relative;
    margin: 20px 0;
    padding: 20px 0;
  }
  
  .timeline::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    background: linear-gradient(to bottom, #1e3c72, #2a5298);
    left: 50%;
    margin-left: -1px;
  }
  
  .timeline-item {
    margin-bottom: 20px;
    position: relative;
  }
  
  .timeline-item::before {
    content: '';
    position: absolute;
    left: 50%;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #00bfff;
    margin-left: -10px;
    top: 0;
  }
  
  .date {
    font-weight: bold;
    color: #fff;
    margin-bottom: 5px;
  }
  
  .timeline-content {
    position: relative;
    width: 50%;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .timeline-content h3, .timeline-content p {
    margin: 0;
  }
  
  .timeline-content h3 {
    margin-bottom: 10px;
  }
  
  .timeline-content p {
    color: #ddd;
  }
  
  .timeline-item:nth-child(odd) .timeline-content {
    left: 0;
  }
  
  .timeline-item:nth-child(even) .timeline-content {
    left: 50%;
  }
  