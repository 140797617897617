.circuit-container {
    width: 100%;
    height: 200px; /* Ajusta según sea necesario */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .circuit {
    width: 100%;
    height: 100%;
    stroke: #1e3c72;
    stroke-width: 0.5;
    fill: transparent;
  }
  
  circle {
    fill: #1e3c72;
  }
  