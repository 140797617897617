.section {
  padding: 60px 20px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  margin-bottom: 20px;
  color: white;
}

.section-title {
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
  color: white;
}
